import axios from "axios";
import { baseUrl, headers } from "../config/env";

export function fetchSurveyList(params) {
  return axios.get(baseUrl + `/survey/list`, { params, headers });
}

export function fetchSurveyDetail(id) {
  return axios.get(baseUrl + `/survey/${id}`, { headers });
}

export function fetchUserSurveyList(params) {
  return axios.get(baseUrl + `/survey/list/user`, { params, headers });
}

export function fetchPostMySurveyList(id) {
  return axios.get(baseUrl + `/survey/user/${id}`, { headers });
}
export function createSurvey(data) {
  return axios.post(baseUrl + `/survey`, data, { headers });
}

export function updateSurvey(data) {
  return axios.put(baseUrl + `/survey`, data, { headers });
}
