<template>
  <div class="container detail">
    <div class="top">
      <div class="inner">
        <h2>{{ title }}</h2>
      </div>
    </div>
    <div class="base">
      <section class="contest">
        <article class="inner">
          <div>
            <div class="participation">
              <h4>
                <span>{{ username }}</span> 님의 참여현황
              </h4>
              <ul v-if="surveyList.length > 0">
                <li v-for="(data, i) in surveyList" :key="i">
                  <div>
                    <span>{{ i + 1 }}차</span>
                    <span class="primary">{{ numberFormat(data.amount) }}원</span>
                    <span>
                      {{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}
                    </span>
                    <span>{{ data.win == true ? "당첨" : "" }}</span>
                  </div>
                </li>
              </ul>
              <p v-else class="nodata">참여 이력이 없습니다.</p>
            </div>
            <div class="flexB price">
              <div class="flex">
                <p>현재가</p>
                <span>{{ numberFormat(avgAmount) }}</span
                >원
              </div>
              <div class="flex">
                <p>시작가</p>
                <span>{{ numberFormat(startAmount) }}</span
                >원
              </div>
            </div>
            <div class="bid flex" v-if="isVisible">
              <h4>가격 공모하기</h4>
              <div class="flexB">
                <div>
                  <input type="text" v-model="amount" @keyup="removeChar" />
                  원을 공모합니다.
                </div>
                <!-- <button class="point">가격 적용</button> -->
                <p>
                  남은 참여횟수
                  <span class="primary">{{ 5 - surveyList.length }}회</span>
                </p>
              </div>
            </div>
            <ul class="des" v-if="isVisible">
              <li>
                &#183; 현재가(평균가) 보다 {{ cutline }}% 이상 또는 {{ cutline }}% 이하의 가격 입력 시 평균 가격에 반영되지 않습니다.
                <br />
                <span class="primary"> &#183; 최대 {{ numberFormat(max) }}원 ~ 최소{{ numberFormat(min) }}원 </span>
                <span> * 과도한 가격변동 방지 차원이며, 반영 제한 범위(상.하위%)는 설문아이템에 따라 다를 수 있습니다. </span>
              </li>
              <li>
                &#183; 다만, 최종 근접가격 입력 선착순 회원 선정 시 고려되므로
                <em>회원님의 희망가격을 소신껏 입력해 주십시요.</em>
                <span>* 설문 아이템별 선정회원 혜택이 없을 수 있습니다.</span>
              </li>
              <li>
                &#183; 각 설문아이템별
                <em>총5회 까지만 입력 가능</em> 합니다.
              </li>
            </ul>
            <div class="comment flex" v-if="isVisible">
              <h4>코멘트</h4>
              <div>
                <textarea v-model="comment" cols="30" rows="10" maxlength="500"></textarea>
              </div>
            </div>
            <div class="buttonWrap" v-if="isVisible">
              <el-button class="point" :loading="loading" @click="submit">참여하기</el-button>
            </div>
          </div>
        </article>
      </section>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import { fetchPostDetail } from "@/api/post";
import { createSurvey, fetchPostMySurveyList } from "@/api/survey";
import { format } from "@/mixins/format";

export default {
  mixins: [format],

  data() {
    return {
      moment: moment,
      postId: "",
      title: "",
      startAmount: 0,
      avgAmount: 0,
      amount: "",
      comment: null,
      cutline: "",
      surveyList: [],
      max: 0,
      min: 0,
      status: "",
      isVisible: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(["username", "userId"]),
  },
  mounted() {
    this.getPost();
  },
  methods: {
    inputNumberFormat(obj) {
      this.amount = this.comma(this.uncomma(obj));
    },

    comma(str) {
      str = String(str);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    },

    uncomma(str) {
      str = String(str);
      return str.replace(/[^\d]+/g, "");
    },
    removeChar(event) {
      if (event.keyCode == 8 || event.keyCode == 46 || event.keyCode == 37 || event.keyCode == 39) return;
      else {
        this.amount = this.comma(this.uncomma(event.target.value.replace(/[^0-9]/g, "")));

        event.preventDefault();
      }
    },
    getPost() {
      this.postId = this.$route.params.id;

      fetchPostDetail(this.postId).then((res) => {
        if (res.data.status == 200) {
          this.title = res.data.data.title;
          this.startAmount = res.data.data.startAmount;
          this.avgAmount = Math.round(res.data.data.avgAmount);
          this.cutline = res.data.data.cutline;
          this.status = res.data.data.status;
          if (this.status != "FINISH") {
            this.isVisible = true;
          }

          let cut = (this.startAmount * this.cutline) / 100;
          if (this.avgAmount == 0) {
            this.max = Math.round(this.startAmount + cut);
            this.min = Math.round(this.startAmount - cut);
          } else {
            this.max = Math.round(this.avgAmount + cut);
            this.min = Math.round(this.avgAmount - cut);
          }
        }
      });

      fetchPostMySurveyList(this.postId).then((res) => {
        if (res.data.status == 200) {
          this.surveyList = res.data.data.result;
        } else {
          return alert(res.data.message);
        }
      });
    },
    submit() {
      this.min = parseInt(this.uncomma(this.min));
      this.amount = parseInt(this.uncomma(this.amount));
      if (this.amount == "") {
        return alert("가격을 입력해 주세요.");
      } else if (this.amount < this.min) {
        return alert(`평균가격 ${this.cutline}% 이하의 가격입니다. 금액을 다시 입력해주세요.`);
      } else if (this.amount > this.max) {
        return alert(`평균가격 ${this.cutline}% 이상의 가격입니다. 금액을 다시 입력해주세요.`);
      }
      let data = {
        postId: this.postId,
        amount: this.amount,
        comment: this.comment,
      };
      this.loading = true;
      createSurvey(data).then((res) => {
        if (res.data.status == 200) {
          this.$store.dispatch("getAccountInfo");
          this.$router.push("/survey/done");
        } else {
          return alert(res.data.message);
        }
        this.loading = false;
      });
    },
  },
};
</script>
