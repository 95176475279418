import axios from "axios";
import { baseUrl, headers } from "../config/env";

export function fetchPostList(params) {
  return axios.get(baseUrl + `/post/list`, { params, headers });
}

export function fetchPostDetail(id) {
  return axios.get(baseUrl + `/post/${id}`, { headers });
}

export function fetchSurveyList(id, params) {
  return axios.get(baseUrl + `/post/${id}/review/list`, { params, headers });
}

export function createPostLike(data) {
  return axios.post(baseUrl + `/post/like`, data, { headers });
}

export function fetchTopPostList() {
  return axios.get(baseUrl + `/post/top/list`, { headers });
}

export function fetchPostEarlybirdList() {
  return axios.get(baseUrl + `/post/earlybird/list`, { headers });
}

export function deletePost(id) {
  return axios.delete(baseUrl + `/admin/post/${id}`, { headers });
}
